.edit-profile-modal__submit-button {
  margin-top: 28px;
  padding: 8px 16px;
  width: max-content;
  border: none;
  border-radius: 4px;
  background-color: rgb(0, 0, 0);
  color: white;
  font-family: 'Cabinet Grotesk';
  font-size: 16px;
  cursor: pointer;
}

.edit-profile-modal__submit-button:disabled {
  background-color: rgba(0, 0, 0, 0.3);
  cursor: not-allowed;
}

.edit-profile-modal__submit-button:hover {
  background-color: rgba(0, 0, 0, 0.6);
  opacity: 0.5;
}