footer {
  display: flex;
  height: 20px;
  margin: 85px 40px 24px;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  line-height: 1.2;
}

.footer__developer {
  margin-right: auto;
}

.footer__year {
  margin: 0;
}
