header {
  display: flex;
  height: 40px;
  padding: 0;
  margin: 32px 40px 0;
  align-items: center;
  font-weight: 700;
  font-size: 20px;
  line-height: 1.2;
}

.header__logo {
  padding: 0;
  margin: 0 24px 0 0;
  height: 40px;
}

.header__details {
  padding: 0;
  margin-right: auto;
}

.header__auth-buttons {
  display: flex;
  align-items: center;
}

.header__signup-button {
  padding: 0;
  margin: 0 20px;
  text-decoration: none;
  border: none;
  background: none;
  font-weight: 700;
  font-style: bold;
  color: #000;
  cursor: pointer;
}

.header__signin-button {
  padding: 0;
  margin-right: 6px;
  text-decoration: none;
  border: none;
  background: none;
  font-weight: 700;
  font-size: 20px;
  color: #000;
  cursor: pointer;
}

.header__add-clothes-button {
  margin: 0 20px;
  padding: 0;
  border: none;
  background-color: transparent;
  font-family: 'Cabinet Grotesk';
  font-weight: 700;
  font-size: 20px;
  line-height: 1.2;
}

.header__add-clothes-button:hover {
  cursor: pointer;
}

.header__profile {
  text-decoration: none;
  display: flex;
  align-items: center;
}

.header__profile:visited {
  color: black;
}

.header__profile:hover {
  cursor: pointer;
}

.header__user-name {
  margin-right: 12px;
}

.header__user-pic {
  width: 40px;
  height: 40px;
}
