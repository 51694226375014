.sidebar {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 380px;
}

.sidebar__profile {
  text-decoration: none;
  display: flex;
}

.sidebar__user {
  margin: 0 0 20px 0;
  display: flex;
  align-items: center;
}

.sidebar__user-name {
  font-size: 20px;
  font-weight: 700;
}

.sidebar__user-pic {
  width: 56px;
  height: 56px;
  margin-right: 16px;
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 50%;
}

.sidebar__change-profile-button {
  padding: 0;
  margin: 4px 20px 4px 0;
  border: 0;
  cursor: pointer;
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
  background: none;
}

.sidebar__logout-button {
  padding: 0;
  margin: 4px 20px 4px 0;
  border: 0;
  cursor: pointer;
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
  background: none;
}