.delete-modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
}

.delete-modal__content {
  position: fixed;
  display: flex;
  flex-direction: column;
  background-color: white;
  max-width: 670px;
  width: 100%;
  border-radius: 20px;
}

.delete-modal__close-button {
  position: absolute;
  top: 22px;
  right: 30px;
  width: 16px;
  height: 16px;
  align-self: flex-end;
  background: transparent center url("../../images/close.svg") no-repeat;
  border: none;
  cursor: pointer;
}

.delete-modal__form {
  display: flex;
  flex-direction: column;
  margin: 60px 0;
  align-items: center;
}

.delete-modal__text {
  margin: 0;
  text-align: center;
}

.delete-modal__confirm-button {
  margin-top: 40px;
  padding: 0;
  border: none;
  background: none;
  font-family: 'Cabinet Grotesk';
  font-size: 20px;
  font-weight: 700;
  color: #FF4D00;
}

.delete-modal__confirm-button:hover {
  cursor: pointer;
}

.delete-modal__cancel-button {
  margin-top: 24px;
  padding: 0;
  border: none;
  background: none;
  font-family: 'Cabinet Grotesk';
  font-size: 20px;
  font-weight: 700;
}

.delete-modal__cancel-button:hover {
  cursor: pointer;
}