.form-modal__container {
  padding: 28px;
}

.form-modal__title {
  margin: 0 0 24px;
  font-size: 16px;
}

.form-modal__form {
  display: flex;
  flex-direction: column;
}

.form-modal__submit-button {
  margin-top: 28px;
  padding: 8px 16px;
  width: max-content;
  border: none;
  border-radius: 4px;
  color: white;
  background-color: rgba(0, 0, 0, 0.3);
  font-family: 'Cabinet Grotesk';
  font-size: 16px;
}

.form-modal__submit-button:hover {
  cursor: pointer;
}