.card {
  display: flex;
  flex-direction: column;
  width: 325px;
  height: 328px;
}

.card:hover {
  cursor: pointer;
}

.card__title {
  display: flex;
  justify-content: center;
  align-content: center;
  position: absolute;
  margin: 15px auto 0;
  padding: 2px 12px;
  align-self: center;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.6);
  background-color: white;
  border-radius: 4px;
}

.card__like-button {
  justify-self: center;
  border: none;
  margin-left: 10px;
  padding: 0;
  position: static;
  background: none;
}

.card__like-button_hidden {
  display: none;
}

.card__image {
  width: 100%;
  height: 100%;
  border: 1px rgba(0, 0, 0, 0.3) solid;
  border-radius: 20px;
}
