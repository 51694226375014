.preview-modal__image {
  max-width: 500px;
  max-height: 500px;
  border-radius: 20px 20px 0 0;
}

.preview-modal__title-section {
  display: flex;
  justify-content: space-between;
  margin: 20px 32px 0;
}

.preview-modal__title {
  margin: 0;
  font-size: 20px;
}

.preview-modal__delete-button {
  margin: 0;
  padding: 0;
  border: none;
  background: none;
  font-family: 'Cabinet Grotesk';
  font-weight: 700;
  font-size: 20px;
  color: #FF4D00;
}

.preview-modal__delete-button_hidden {
  display: none;
}

.preview-modal__delete-button:hover {
  cursor: pointer;
}

.preview-modal__weather-type {
  margin: 12px 0 32px 32px;
  font-size: 20px;
}