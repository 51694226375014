.card-section {
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: repeat(auto-fill, 325px);
  grid-gap: 20px;
}

.card-section__container {
  margin: 40px 32px 0;
  max-width: 1360px;
  display: flex;
  flex-direction: column;

}

.card-section__title {
  margin: 0 0 34px;
  font-size: 20px;
}