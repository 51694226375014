label {
  font-weight: 700;
}

input {
  margin: 8px 0 24px;
  padding: 0;
  border: none;
  border-bottom: 1px solid black;
  color: rgba(0, 0, 0, 0.5);
  font-size: 16px;
}

input[type="radio"] {
  margin: 2px 8px 2px 0;
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Cabinet Grotesk";
  background-color: rgba(0, 0, 0, 0.3);
}

.modal__content {
  position: fixed;
  display: flex;
  flex-direction: column;
  background-color: white;
  max-width: 496px;
  width: 100%;
  border-radius: 20px;
}

.modal__close-button {
  position: absolute;
  top: 22px;
  right: 30px;
  width: 16px;
  height: 16px;
  align-self: flex-end;
  background: transparent center url("../../images/close.svg") no-repeat;
  border: none;
  cursor: pointer;
}
